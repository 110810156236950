import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    theme:"light"
}

let loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        storeTheme:function(state,action){
            state.theme = action.payload
        }
    }
})

export const { storeTheme} = loginSlice.actions;
export default loginSlice.reducer;