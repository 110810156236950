import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../layout/FallbackPage/FallbackPage';

const LazyHome = lazy(() => import('./Home'));

const Home = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyHome {...props} />
  </Suspense>
);

export default Home;
