import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Navbar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import ThemeSwitch from '../ThemeSwitch/ThemeSwitch';
import {useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


const Navbar = () => {

  const { theme} = useSelector((state) => state.login)

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const scrollToSection = () => {
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const top = element.offsetTop;
          window.scrollTo({
            top,
            behavior: "smooth",
          });
        }
      }
    };
    scrollToSection();
  }, [location]);

  const handleNavClick = (sectionId) => {
    navigate(`#${sectionId}`);
  };



  return (
    <header className={styles.Navbar__head}>
      <div className={styles.Navbar__head__left}>
      </div>
      <div className={styles.Navbar__head__center}>
        <h1 className={styles.Navbar__head__center__span}>
          {/* Tanmay kaushik. */}
          <nav>
        {/* <ul>
          <li>
            <button onClick={() => handleNavClick("about")}>About</button>
          </li>
          <li>
            <button onClick={() => handleNavClick("services")}>Services</button>
          </li>
          <li>
            <button onClick={() => handleNavClick("contact")}>Contact</button>
          </li>
        </ul> */}
      </nav>
          <ThemeSwitch />
        </h1>
      </div>
      <div className={styles.Navbar__head__right}>
      </div>
    </header>
  )
};

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
