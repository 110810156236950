import { Spin } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { Suspense } from 'react';
import { Provider } from "react-redux";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import Welcome from './pages/Welcome/Welcome.lazy';
import ProtectedRoute from './protectedRoutes/ProtectedRoute';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import FallbackPage from './layout/FallbackPage/FallbackPage';
import Layout from './layout/Layout/Layout';
import Home from './pages/Home/Home.lazy';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<FallbackPage />} persistor={persistor}>
        <Router>
          <Suspense fallback={<FallbackPage />}>
            <Layout>
              <Routes>
                <Route path="/" element={<Navigate replace to="/portfolio" />} />
                <Route
                  path="/"
                  element={
                    <Outlet />
                  }>
                  <Route path="portfolio" element={<Home />} />
                </Route>
              </Routes>
            </Layout>
          </Suspense>
        </Router>
      </PersistGate>

    </Provider>

  );
}

export default App;
