import React from 'react'
import "./Loading.css";

const Loaders = () => {
    return (
        <div class="loading">
            <span>T k</span>
        </div>)
}

export default Loaders