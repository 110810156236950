import React from 'react';
import styles from './Layout.module.scss';
import Navbar from "../Navbar/Navbar"

const Layout = (props) => (
  <div className={styles.Layout}>
    <Navbar />
    <main className={styles.Layout__body}>
      {props.children}
    </main>
  </div>
);

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
