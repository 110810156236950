import React from 'react';
import PropTypes from 'prop-types';
import styles from './FallbackPage.module.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Loaders from '../../DesignComponents/Loaders/Loaders';

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;


const FallbackPage = () => (
  <div className={styles.FallbackPage}>
    <Loaders/>
  </div>
);

FallbackPage.propTypes = {};

FallbackPage.defaultProps = {};

export default FallbackPage;
